<template>
  <div>
    <modal-cart />
    <modal-finding />
    <modal-order-successful />
    <app-mobile-drawer />
    <app-header />
    <main class="main">
      <slot />
    </main>
  </div>
</template>

<script>
import AppMobileDrawer from '@/components/common/AppMobileDrawer'
import AppHeader from '@/components/common/AppHeader'
import ModalCart from '@/components/modal/ModalCart'
import ModalFinding from '@/components/modal/ModalFinding'
import ModalOrderSuccessful from '@/components/modal/ModalOrderSuccessful'

export default {
  name: 'FullPageTemplate',
  components: {
    AppMobileDrawer,
    AppHeader,
    ModalCart,
    ModalFinding,
    ModalOrderSuccessful
  }
}
</script>