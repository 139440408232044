<template>
  <full-page-template>
    <div class="bg-collaboration" :class="{ active : loaded }" />
    <div class="p-collaboration" :class="{ active : loaded }">
      <div class="container">
        <div class="wrapper">
          <div class="content mb-m">
            <h1 class="section-title mb-m">
              Сотрудничество
            </h1>
            <div class="wrapper-list">
              <p class="fw--bold fs--medium mb-m">
                Приглашаем к сотрудничеству:
              </p>
              <ul class="invites-list">
                <li class="list-item">
                  Кредитные организации
                </li>
                <li class="list-item">
                  Страховые компании
                </li>
                <li class="list-item">
                  Телекомуникационные компании
                </li>
                <li class="list-item">
                  Автосалоны
                </li>
                <li class="list-item">
                  Авиакомпании
                </li>
                <li class="list-item">
                  Ретейл копании
                </li>
                <li class="list-item">
                  Центры государственных услуг
                </li>
                <li class="list-item">
                  Туристические агенства
                </li>
                <li class="list-item">
                  Коммуникационные агенства
                </li>
                <li class="list-item">
                  Зоомагазины
                </li>
                <li class="list-item">
                  Ветеринарные клиники и прочие компании
                </li>
              </ul>
            </div>
            <div class="divider" />
            <div class="wrapper-list">
              <p class="fw--bold fs--medium mb-m">
                Преимущества сотрудничества с CLICKTOID:
              </p>
              <ul class="features-list">
                <li class="list-item">
                  Продукт подходит для клиентов любого бизнеса
                </li>
                <li class="list-item">
                  Пакет услуг CLICKTOID находится в поле зрения у клиента и напоминает о Вашей компании
                </li>
                <li class="list-item">
                  Сотрудничетсво с CLICKTOID - дополнительный доход для вашей компании
                </li>
              </ul>
            </div>
            <div class="divider" />
            <p class="fw--bold fs--medium mb-m">
              Презентация CLICKTOID:
            </p>
            <a href="assets/Продукты CLICKTOID_сайт.pdf" download target="_blank">
              <app-button class="mb-l" title="Скачать презентацию" description="PDF, 30 МБ">
                <icon-arrow-right class="icon arrow-down" />
              </app-button>
            </a>
          </div>
          <div class="wrapper-img img-circle">
            <img src="@/assets/img/img-collaboration.png">
          </div>
        </div>
      </div>
    </div>
    <app-footer />
  </full-page-template>
</template>

<script>
import AppFooter from '@/components/common/AppFooter'
import FullPageTemplate from '@/layouts/FullPageTemplate'
import AppButton from '@/components/common/AppButton'
import IconArrowRight from '@/assets/img/icons/arrow-right.svg'

export default {
  name: 'Collaboration',
  components: {
    AppFooter,
    FullPageTemplate,
    AppButton,
    IconArrowRight
  },
  data () {
    return {
      loaded: false
    }
  },
  mounted () {
     if (document.readyState === 'complete') {
       this.loaded = true
    } else {
      window.onload = () => {
        this.loaded = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .bg-collaboration {
    &.active {
      opacity: 1;
      transform: none;
    }
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('~@/assets/img/bg-collaboration.png') center / cover no-repeat;
    background-attachment: fixed;
    transition: 1s;
    transform: scale(1.2);
    opacity: 0;
    @include max-w-md {
      background: url('~@/assets/img/bg-collaboration-mobile.png') center / cover no-repeat;
      background-attachment: fixed;
      background-position-y: -20px;
    }
  }
  .p-collaboration {
    overflow: hidden;
    position: relative;
    min-height: 100vh;
    &.active {
      .wrapper {
        .wrapper-img {
          right: 0 !important;
        }
        .content {
          left: 0 !important;
        }
      }
    }
    .wrapper {
      z-index: 9;
      display: flex;
      justify-content: space-between;
      @include max-w-md {
        flex-direction: column-reverse;
        justify-content: center;
      }
      .wrapper-img, .content {
        position: relative;
        transition: 1s;
      }
      .wrapper-img {
        right: -2000px;
        max-width: 400px;
        @include max-w-md {
          align-self: center;
        }
      }
      .content {
        scrollbar-width: none;
        left: -2000px;
        overflow: auto;
        width: 100%;
        max-width: 420px;
        max-height: 660px;
        @include min-w-xs {
          padding-top: 140px;
        }
        &::-webkit-scrollbar { width: 0; }
        .invites-list, .features-list {
          padding-left: 20px;
          li {
            list-style-type: disc;
          }
        }
      }
    }
  }
</style>
